//Estilos PrimeReact
@import 'primereact/resources/themes/saga-blue/theme.css';
@import 'primereact/resources/primereact.min.css';
@import "primeicons/primeicons.css";
@import 'primeflex/primeflex.css';

// font awesome
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css");

//variables comunes
@import "../common/_variables";

//variables de proyecto
@import "./_variables-con";
@import "./flags-sass/_variables";
@import "./flags-sass/_flag-icons-base";
@import "./flags-sass/_flag-icons-list";

//todos los estilos comunes
@import "../common/_all-common";
@import "../common/_utils";
@import "./_reset";

//todos los estilos especificos de proyecto
@import "./_all-con";

//Estilos de plugin de emojis
@import "./_emoji";

