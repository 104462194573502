.calendar {
  --outer-border-radius: 24px;
  --border-color: rgb(233, 236, 239);
  --border: 1px var(--border-color) solid;
  --header-font-size: 0.825rem;
  flex-grow: 1;

  &-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
    flex-basis: 100%;
  }

  &__input {
    &-container {
      flex: 0 0 252px!important;
    }

    display: block;

    .p-datepicker-calendar {
      table-layout: fixed;
      width: 100%;
    }

    .p-datepicker table td > span {
      width: unset;
      height: unset;
      aspect-ratio: 1;
    }
  }

  &--weekly {
    --with-time-column: 4rem;
    --rows: 24;
    --row-height: 40px;

    .message-block-container {
      z-index: 1;
      position: absolute;
      inset: 1px 0.5rem;
    }

    & .calendar__cell:nth-of-type(186) {
      border-bottom-left-radius: var(--outer-border-radius);
    }

    & .calendar__cell:nth-of-type(192) {
      border-bottom-right-radius: var(--outer-border-radius);
    }
  }

  &--monthly {
    --with-time-column: 0rem;
    --rows: 6;
    --row-height: 120px;

    .calendar__cell--time {
      visibility: hidden;
    }

    & .calendar__cell:nth-of-type(42) {
      border-bottom-left-radius: var(--outer-border-radius);
    }

    & .calendar__cell:nth-of-type(48) {
      border-bottom-right-radius: var(--outer-border-radius);
    }
  }

  &__header {
    width: 100%;
    display: grid;
    grid-template-columns: var(--with-time-column) repeat(7, minmax(0, 1fr));
  }

  &__body {
    width: 100%;
    display: grid;
    grid-template-columns: var(--with-time-column) repeat(7, minmax(0, 1fr));
    grid-template-rows: repeat(var(--rows), var(--row-height));
    position: relative;

    & > .calendar__cell:nth-of-type(8n + 2) {
      border-left: var(--border);
    }

    @for $i from 2 through 8 {
      & > .calendar__cell:nth-of-type(#{$i}) {
        border-top: var(--border);
      }
    }
  }

  &__day { 
    font-size: var(--header-font-size);
    padding-block: 0.75rem;
    line-height: 1.2;
    text-align: center;
    background-color: white;
    text-transform: uppercase;
    font-weight: bold;

    &:nth-of-type(2) {
      border-top-left-radius: var(--outer-border-radius);
    }

    &:nth-of-type(8) {
      border-top-right-radius: var(--outer-border-radius);
    }
  }

  &__cell {
    background-color: white;
    border-right: var(--border);
    border-bottom: var(--border);
    padding: 0.125rem 0.25rem;

    &--time {
      font-size: var(--header-font-size);
      background-color: unset;
      text-align: right;
      padding-right: 1rem;
      translate: 0 -0.75rem;
      white-space: nowrap;
      font-weight: bold;
      pointer-events: none;
    }

    &-number {
      display: block;
      text-align: right;
    }
  }
}

.message-block {
  background-color: #9AC987;
  border-radius: 20px;
  padding: 0.75rem;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  &__header {
    font-weight: 700;
  }

  &__footer {
    margin-top: auto;
  }
}

.message-list {
  list-style: none;
  padding-left: 0.5rem;
  margin-bottom: 0.5rem;

  &__item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-radius: 1rem;

    &:hover {
      background-color: hsla(217, 71%, 90%, 0.2);
      cursor: pointer;
    }

    .dot {
        display: inline-block;
        background-color: #9AC987;
        height: 1rem;
        width: 1rem;
        border-radius: 100%;
        flex-shrink: 0;
      }
  }
}

.AutomaticReplyCreate {
  width: 60vw;
  max-width: 100%;

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: #194893 ;
  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover {
    background-color: #194893 ;
  }

  .p-selectbutton {
    display: flex;
  }

  .p-buttonset .p-button:first-of-type {
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
  }

  .p-buttonset .p-button:last-of-type {
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important;
  }

  .p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
    border-radius: 0!important;
  }

  .form-group {
    padding: 0.5rem;
  }

  .input-switch {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .btn {
    margin-block: auto;
    background-color: transparent;
    border: none;
    border-radius: 2rem;

    &--add-schedule {
      color: #194893;
      font-weight: bold;

      &:hover {
        color: #194893!important;
        background-color: hsla(217, 71%, 90%, 0.5)!important;
      }
    }
  }

  .schedule {
    display: grid;
    grid-template-columns: 3fr 3fr 1fr;
  }

  .message {
    position: relative;

    textarea {
      padding-bottom: 3rem!important;
    }

    .ChatActionEmojisItem {
      position: absolute;
      left: 1rem;
      bottom: 1rem;

      &__body__emojis {
        font-size: 1rem;
      }
    }
  }
}

.select-type {
  height: 100%;
  background-color: hsl(0, 0%, 100%);
  border-radius: 6px!important;
  margin-block: 0.5rem;

  .p-button {
    border-radius: 6px!important;
    padding-block: .625rem!important;
  }

  .p-button:first-of-type {
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
  }

  .p-button:last-of-type {
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important;
  }

  .p-button:not(:first-of-type):not(:last-of-type) {
    border-radius: 0!important;
  }
}
