.fib {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.fi {
  @extend .fib;
  position: relative;
  display: inline-block;
  //width: 1.333333 * 1em;
  line-height: 1em;

  &:before {
    content: '\00a0';
    width: 1em;
    height: 1em;
    margin-right: 0.3em;
  }

  &.fis {
    //width: 1em;
  }

  &.p-chip {
    display: inline-flex;
  }
}



@mixin flag-icon($country) {
  .fi-#{$country} {


    @if $flag-icons-use-square {
      &.fis {
        &:before {
          background-image: url(#{$flag-icons-path}#{$flag-icons-square-path}/#{$country}.svg);
        }
      }
    }
  }
}