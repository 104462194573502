body>iframe {
    display: none;
}

.p-menubar.navbar-principal {
    &.blue-nav {
        background: linear-gradient(165deg, $main-color-1, darken($main-color-1, 10));
    }
}

.arrow {
    &.segunda {
        background-image: url('../../img/common/back-image-cta.svg');
    }
}