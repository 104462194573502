.ChatBalloon {
  position: relative;
  box-shadow: 0px 1px 0px rgb(167 158 145 / 31%);
  border-radius: 8px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 7px 10px 10px 10px;
  background-color: var(--clr-chat-balloon);
  word-break: break-word;

  &__body {

    &__media {

      .image,
      .video {
        width: 100%;
        max-width: 400px;
        height: auto;
      }

      .sticker {
        max-width: 150px;
      }

      .document {

        width: 50px;
        margin-inline: auto;

        .pi {
          font-size: 3rem;
        }
        &--pdf{
          width: 100%;
        }
      }

      audio::-webkit-media-controls-play-button,
      audio::-webkit-media-controls-panel {
        background-color: #fff;
        color: #fff;
      }
    }

    &__text {
      p {
        margin: 0;
      }
    }

    &__ephemeral {
      font-style: italic;
      color: #999;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    font-size: 0.7875em;

    &__type {
      color: #667781;
      margin-right: auto;
    }

    &__agent {
      color: #667781;
      margin-right: auto;
    }

    &__readAt {
      color: #73c9ef;
    }

    &__failedAt {
      color: #ff0000
    }

    &__deliveredAt {
      color: #667781;
    }

    &__sentAt {
      color: #667781;
    }

    &__writtenAt {
      color: #667781;
    }

    &__messageIsViewed {
      color: #73c9ef;
    }

    &__date {
      color: #667781;
      margin-left: 0.5em;
    }
  }

  &--sender {
    --clr-chat-balloon: #D9FDD3;
    margin: 10px 18px 10px auto;
    min-width: 35%;
    max-width: 60%;

    &+.ChatContentItem--message-sent {
      margin-top: -6px;

      &::before {
        content: none;
      }
    }

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0px 0px 18px 18px;
      border-color: transparent transparent transparent #D9FDD3;
      right: -14px;
      top: 0;
      position: absolute;
    }

  }

  &--reciever {
    --clr-chat-balloon: #FFFFFF;
    margin: 10px auto 10px 18px;
    min-width: 20%;
    max-width: 60%;

    &+.ChatContentItem--message-received {
      margin-top: -6px;

      &::before {
        content: none;
      }
    }

    &:has(img.sticker) {
      background-color: transparent;
      box-shadow: none;

      &::before {
        content: none;
      }


    }

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0px 18px 18px 0px;
      border-color: transparent white transparent transparent;
      left: -14px;
      top: 0;
      position: absolute;
    }
  }

  &--comment {
    --clr-chat-balloon: #D6D3FD;
    margin: 10px auto 10px auto;
    width: 70%;

  }

  &--event {
    --clr-chat-balloon: #FFEECD;
    margin: 10px auto 10px auto;
    min-width: 20%;
    color: #6a6a6a;

    span {
      display: block;
    }

  }



  .spinner {
    text-align: center;
  }

  .download {
    position: relative;
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;

    i {
      display: none;
      margin: auto;
    }
  }

  .icon {
    text-align: center;

    i {
      font-size: 4em;
      
    }
  }

  .wa-balloon {
    background-color: transparent;
    box-shadow: none;

    &.wa-sender {
      background-color: transparent;

      .wa-svg-balloon-container-sender {
        display: none;
      }
    }

    .p-button {
      &.p-button-link {
        &:is(:focus, :hover) {
          box-shadow: none;
          text-decoration: none;
        }
      }
    }
  }
}
