.p-listbox.lista-campaigns {
    width: 100%;
    border: none;
}
.dato-terciario{
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}
.dashboard{
    &__header-filters{
        & > *{
            // width: 30%;
        }
    }
}