.sd .profile-photo {
    margin: auto;
    background-color: gray;
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;

    .p-component.p-button {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        display: block;
        text-transform: uppercase;
        font-size: 0.7em !important;
        line-height: 1.2;

        background: rgba($secondary-color-1, 0.8);

        .p-button-icon {
            margin: 0 0 0.3em 0;
        }
    }

    &:hover {
        .p-button {
            opacity: 1;
        }
    }
}

.profile-photo-img {

    border-radius: 50%;
    display: block;

}

.navAccount {
    & > a {
        span.p-menuitem-text {
            height: 48px !important;
            width: 48px !important;
            background-color: #53BDEB;
            border-radius: 50% !important;
            color: white !important;
            width: 100%;
            display: flex;
            align-items:center;
            justify-content:center;
        }

        span.pi-circle-fill {
            position: absolute;
            bottom: 40px;
            right: 5px;

            &::before {
                position: absolute;
                border: 3px solid white;
                border-radius: 50%;
            }
        }

        span.p-submenu-icon {
            display: none;
        }
    }

    $online: #3a8939;
    $toGetOut: orange;
    $away: gray;

    &.ONLINE {
        span.pi-circle-fill {
            color: $online !important;
        }
    }

    &.TO_GET_OUT {
        span.pi-circle-fill {
            color: $toGetOut !important;
        }
    }

    &.AWAY {
        span.pi-circle-fill {
            color: $away !important;
        }
    }
    
    &.DISCONNECTED {
        span.pi-circle-fill {
            color: $away !important;
        }
    }

    .p-submenu-list {
        right: 0px;

        .email {
            a {
                border-bottom: 1px solid #C5C5C5;

                &:hover {
                    background: none;
                    cursor: unset;
                }
            }
        }

        .logout {
            border-top: 1px solid #C5C5C5;
        }
    }
}