.wa-svg-balloon-container-sender,
.wa-svg-balloon-container-receiver {
    height: 13px;
    position: absolute;
    top: -1px;
}

.wa-svg-balloon-container-sender {
    left: -8px;
}

.wa-svg-balloon-container-receiver {
    right: -8px;
}


.wa-container {
    background: rgb(239, 234, 226);
    padding: 20px;
    border-radius: 0.5rem;
    width: 100%;
    position: relative;
    min-height: 92px;

    p {
        margin: 0px 0px 1rem;
    }
}

.wa-balloon {
    background-color: white;
    width: fit-content;
    max-width: 100%;
    padding: 6px 7px 8px 9px;
    color: rgb(17, 27, 33);
    font-size: 14.2px;
    line-height: 19px;
    box-shadow: rgba(125, 155, 175, 0.13) 0px 1px 0.5px 0px;
    position: relative;


    .wa-header-title {
        font-size: 15px;
        font-weight: 600;
        display: block;
        margin-bottom: 4px;
    }

    .wa-footer {
        font-size: 13px;
        display: block;
        color: rgb(134, 150, 160);
        margin-top: 4px;
    }

    p {
        margin: 0;
    }
    &.wa-sender {
        border-top-right-radius: 7.5px;
        border-bottom-right-radius: 7.5px;
        border-bottom-left-radius: 7.5px;
        background-color: white;
    }

    &.wa-receiver {
        margin-left: auto;
        background-color: #E4FFCA;
        border-top-left-radius: 7.5px;
        border-bottom-right-radius: 7.5px;
        border-bottom-left-radius: 7.5px;
    }

}

.wa-back {
    background: url("../../img/cta/bg-chat-tile.png");
    opacity: 0.4;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}


.chart-legend-icon {
    width: 16px;
    height: 15px;

    display: inline-block;
    vertical-align: middle;
    display: inline-block;
    //margin-top: 4px;

    &+span {
        vertical-align: middle;
        display: inline-block;
    }
}

.icon-read {
    background-image: url('../../img/cta/wa-icons/double-check-blue.svg');
}

.icon-delivered {
    background-image: url('../../img/cta/wa-icons/double-check.svg');
}

.icon-sent {
    background-image: url('../../img/cta/wa-icons/single-check.svg');
}

.icon-pending {
    background-image: url('../../img/cta/wa-icons/clock.svg');
}

.wa-buttons {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-top: 1px solid #111b211a;
    margin-bottom: -8px;
    margin-top: 4px; //temporal debe cambiar cuando tengamos fechas?

    .p-button {

        min-width: 50%;
        color: rgb(0, 157, 226);

        padding: 6px 7px 8px 9px;

        &:hover .p-button-label,
        .p-button-label:hover {
            text-decoration: none !important;
        }
    }
}
// .sd{
//     .template-mapping-template-preview{
//         .wa-container{
//             min-height: 92px;
//         }
//     }

// }