/*---------------------------------------
    @variables
-----------------------------------------*/
$menubar: (
    height: 104px
);

$Chat: (
    AgentsSection: (AgentsHeader: (height: 80px), AgentsFooter: (height: 60px)),
    ConversationsSection: (ConversationsHeader: (height: 80px), ConversationsFooter: (height: 40px)),
    ConversationsUnassignedSection: (ConversationsHeader: (height: 60px)),
    ChatSection: (ConversationInfo: (height: 80px),
        ConversationAddMessage: (height: 70px)),
);

/*---------------------------------------
    @animations
-----------------------------------------*/
@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }

    100% {
        background-position: 468px 0
    }
}

/*---------------------------------------
    @utils
-----------------------------------------*/
.animated-background {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #F6F6F6;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 800px 104px;
    position: relative;
}

/*---------------------------------------
    @view AgentChat
-----------------------------------------*/
.Chat {
    position: relative;
    display: flex;
    height: calc(100vh - map-get($menubar, height));
    color: #1a1a1a;

    .p-dialog-mask:has(.employee-status) {
        position: absolute;
        z-index: 5;
    }

    .LinesSection {
        background: white;
        z-index: 4;
        box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.02);
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.02);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.02);

        .LineIconItem {
            &__body {
                margin-top: 22px;
                margin-left: auto;
                margin-right: auto;
                cursor: pointer;
            }
        }
    }

    .AgentsSection {
        transform: translateX(-345px);
        transition: transform 400ms ease-in-out;
        z-index: 3;

        &.transition {
            transform: translateX(0);
        }

        .AgentsHeader {
            height: map-get($Chat, AgentsSection, AgentsHeader, height);
            background-color: #f3f3f3;
            border-bottom: 1px solid #bfc1c3;


            &__body {
                .AgentsHeaderInfoItem {
                    padding-left: 10px;
                }

                &__info {
                    padding-left: 10px;
                    min-width: 0;
                    overflow: hidden;

                    &__name {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-weight: 700;
                    }

                    &__phone {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        .AgentConversationsList {
            background-color: #f3f3f3;

            &__body {
                overflow-y: auto;
                height: calc(100vh - map-get($menubar, height) - map-get($Chat, AgentsSection, AgentsHeader, height) - map-get($Chat, AgentsSection, AgentsFooter, height));

                .AgentConversationItem {
                    cursor: pointer;
                    border-top: 1px solid #e3e7e9;

                    &:is(:hover, :focus, .active) {
                        background-color: #e0e2e4;
                    }
                }
            }

            &__footer {
                height: map-get($Chat, AgentsSection, AgentsFooter, height);

                .AgentConversationItem {
                    cursor: pointer;
                    border-top: 1px solid #e3e7e9;

                    &:is(:hover, :focus, .active) {
                        background-color: #e0e2e4;
                    }
                }
            }
        }
    }

    .ConversationsSection {
        transform: translateX(-345px);
        transition: transform 200ms ease-in-out;
        z-index: 2;

        &.transition {
            transform: translateX(0);
        }

        .ConversationsHeader {
            height: map-get($Chat, ConversationsSection, ConversationsHeader, height);
            background-color: white;
            border-bottom: 1px solid #bfc1c3;

            &__body {
                padding-top: 0.875em;
                display: flex;
                flex-direction: row;

                .AgentConversationItem {
                    overflow: hidden;

                    &__body {
                        margin-block: 0;
                    }
                }

                .ConversationsHeaderInfoItem {
                    padding-left: 10px;
                }

                &__line {
                    display: grid;


                    padding-left: 15px;
                    min-width: 0;

                    &__name {
                        font-weight: 700;
                        font-size: 1rem;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    &__phone {
                        font-size: 0.875rem;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            .LineIconItem {
                &__body {
                    &__isAlert {
                        display: none;
                    }
                }
            }
        }

        .ContactConversationsList {
            background-color: white;
            display: grid;
            grid-template-rows: 1fr;
            min-height: 0;

            &__body {
                overflow-y: auto;

                .ContactConversationItem {
                    cursor: pointer;
                    border-top: 1px solid #e3e7e9;

                    &:is(:hover, :focus, .active) {
                        background-color: #e7ebec;

                    }

                    .ContactConversationItemInfo {
                        &__body {
                            &__info {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            &__alias {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }

                    }

                }
            }

            &__footer {
                height: map-get($Chat, ConversationsSection, ConversationsFooter, height);

                // Deshabilitado temporalmente para cuando se pueda hacer clic en la lista de 
                // conversaciones encoladas en cuenta agente
                .ContactConversationItem {
                    // cursor: pointer;
                    border-top: 1px solid #e3e7e9;

                    &__body {
                        padding-bottom: 0;
                    }

                    .ContactConversationItemInfo {
                        &__body {
                            &__name {
                                font-weight: 700;
                            }
                        }
                    }

                    &:is(:hover, :focus, .active) {
                        // background-color: #e0e2e4;
                    }
                }
            }
        }
    }

    .ConversationsUnassignedSection {
        transform: translateX(-345px);
        transition: transform 200ms ease-in-out;
        z-index: 2;

        &.transition {
            transform: translateX(0);
        }

        .ConversationsHeader {
            height: map-get($Chat, ConversationsUnassignedSection, ConversationsHeader, height);
            background-color: white;
            border-bottom: 1px solid #bfc1c3;

            &__body {
                padding-top: 0.875em;
                display: flex;
                flex-direction: row;

                .AgentConversationItem {
                    overflow: hidden;

                    &__body {
                        margin-block: 0;
                    }
                }

                .ConversationsHeaderInfoItem {
                    padding-left: 10px;
                }

                &__line {
                    display: grid;


                    padding-left: 15px;
                    min-width: 0;

                    &__name {
                        font-weight: 700;
                        font-size: 1rem;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    &__phone {
                        font-size: 0.875rem;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            .LineIconItem {
                &__body {
                    &__isAlert {
                        display: none;
                    }
                }
            }
        }

        .ContactConversationsList {
            background-color: white;

            &__body {
                overflow-y: auto;
                height: calc(100vh - map-get($menubar, height) - map-get($Chat, ConversationsUnassignedSection, ConversationsHeader, height));

                .ContactConversationItem {
                    // cursor: pointer;
                    border-top: 1px solid #e3e7e9;

                    // &:is(:hover, :focus, .active) {
                    //     background-color: #e7ebec;
                    // }

                    .ContactConversationItemInfo {
                        &__body {
                            &__info {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            &__alias {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }

                    }

                }
            }
        }
    }

    .ChatSection {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        &__body {
            width: 100%;
            display: flex;
        }

        &__conversation-info {
            width: 100%;
            padding-right: 2em;
            display: flex;
            justify-content: space-between;
            height: map-get($Chat, ChatSection, ConversationInfo, height);
            background-color: #f6f8fc;

            .ContactConversationItem {
                min-width: 0;

                &__body {
                    .ContactConversationItemInfo {
                        font-weight: 700;
                        width: auto;

                        &__body {
                            .undefined__body__phone {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            .undefined__body__alias {
                                font-weight: 400;
                            }

                        }


                    }
                }
            }

            .ChatHeaderActionsSection {
                &__body {
                    height: 100%;
                    display: flex;
                    align-items: center;

                    .p-splitbutton.p-component {
                        button {
                            margin-left: 0;
                        }
                    }

                    &__action {
                        >button {
                            display: none;
                        }

                        @media screen and (min-width:940px) {
                            >button {
                                display: block;
                            }

                            >.p-splitbutton {
                                display: none;
                            }
                        }


                    }

                    button {
                        margin-left: 10px;
                        padding-block: 0.3em !important;
                    }
                }
            }
        }

        &__conversation {
            flex: 3;
            height: calc(100vh - map-get($menubar, height) - map-get($Chat, ChatSection, ConversationInfo, height));
            background-color: #f1ede6;
            background-image: url(../../img/cta/NEO-CON-Chat-background.png);
            background-size: 280px;
            position: relative;
            display: flex;
            flex-flow: column;

            &__content {
                flex: 1 1 auto;
                overflow-y: auto;
            }

            &__attachment-file {
                z-index: 1;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;

                &.hide {
                    display: none;
                }

                &-content {
                    position: relative;
                    height: 100%;

                    &-header {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        height: 40px;
                        background-color: #E6E8EA;

                        span {
                            margin-top: auto;
                            margin-bottom: auto;
                        }

                        i {
                            position: absolute;
                            margin-top: 10px;
                            right: 20px;
                            cursor: pointer;
                        }
                    }

                    .p-fileupload {
                        height: calc(100% - 40px);
                        position: relative;


                        .p-fileupload-buttonbar {
                            position: initial;

                            .p-button {
                                position: absolute;
                                bottom: 28px;
                                bottom: 55%;
                                left: 50%;
                            }

                        }

                        &-content {
                            padding-top: 2em;
                            overflow: auto;
                            border-radius: 0;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }

                    .p-fileupload-files {
                        max-height: 100%;
                    }

                    &-footer {
                        position: absolute;
                        bottom: 20px;
                        right: 30px;
                        cursor: pointer;

                        .p-button.p-component.p-button-rounded {
                            height: 2.357em;
                            width: 2.357em;
                            padding: 0 !important;

                        }
                    }
                }
            }

            &__quickly-reply {
                z-index: 1;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: white;

                &.hide {
                    display: none;
                }

                &-actions {
                    height: 40px;
                }

                &-content {
                    position: relative;
                    height: 100%;

                    &-header {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        height: 40px;
                        background-color: #E6E8EA;

                        span {
                            margin-top: auto;
                            margin-bottom: auto;
                        }

                        i {
                            position: absolute;
                            margin-top: 10px;
                            right: 20px;
                            cursor: pointer;
                        }
                    }

                    &-replies {
                        padding-inline: 10px;
                        height: calc(100% - 40px - 40px);
                        overflow-x: auto;

                        &-replie {
                            margin-top: 20px;

                            div {
                                cursor: pointer;
                                border: 1px solid #c6c8ca;
                                border-radius: 10px;
                                padding: .5em;
                            }
                        }
                    }
                }
            }

            &--finished-conversation,
            &--expired-conversation,
            &--reassigned-conversation,
            &--not-allowed-write {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-content: center;
            }

            &--not-allowed-write {
                background-color: white;
                min-height: map-get($Chat, ChatSection, ConversationAddMessage, height);
                border-top: 1px solid gray;

                p {
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }

            &--notificationNewMessages {
                position: absolute;
                z-index: 1;
                bottom: map-get($Chat, ChatSection, ConversationAddMessage, height);
                left: 50%;
                transform: translate(-50%, -50%);

                &__body {
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    margin-left: auto;
                    margin-right: auto;
                    background-color: #194893;
                    border-radius: 20px;
                    padding: 7px 12px 7px 9px;
                    cursor: pointer;

                    &__count {
                        color: white;
                        background-color: #65D072;
                        border-radius: 1.1em;
                        padding-inline: 0.4em;
                        min-width: 16px;
                        height: 20px;
                        text-align: center;
                        font-size: .9em;
                    }

                    &__title {
                        font-size: 0.8125rem;
                        margin-left: 7px;
                        color: white;
                    }
                }
            }

            &__add-message {
                flex: 0 1 map-get($Chat, ChatSection, ConversationAddMessage, height);
                background-color: #d9d9d9;
                display: flex;
                gap: .5em;
                padding: 5px;
                position: relative;

                .ChatActionEmojisItem {
                    width: 6%;
                    text-align: center;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    align-content: flex-end;

                    &__body {
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: .25em;
                    }
                }

                .ChatActionAttachmentFileItem {
                    width: 6%;
                    text-align: center;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    align-content: flex-end;

                    &__body {
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: .25em;
                    }
                }

                .ChatActionQuickReplyItem {
                    width: 6%;
                    text-align: center;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    align-content: flex-end;

                    &__body {
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: .25em;
                    }
                }

                .ChatActionInputItem {
                    width: 100%;
                    margin-top: auto;
                    margin-bottom: auto;

                    textarea {
                        max-height: 200px;
                    }
                }

                .ChatActionButtonSendMessageItem {
                    width: 6%;
                    text-align: center;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    align-content: flex-end;

                    &__body {
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: .25em;
                    }
                }

                .ChatContentCommentIconItem {
                    display: flex;
                    position: absolute;
                    top: -60px;
                    right: 20px;

                    &__body {
                        .p-button {
                            height: 2.5em;
                            width: 2.5em;
                            padding: 0 !important;


                            &::after {
                                padding-bottom: 100%;
                            }
                        }

                        span {
                            font-size: 1.2em;
                        }
                    }
                }
            }
        }

        &__contact-info {
            flex: 2;
            height: calc(100vh - map-get($menubar, height) - map-get($Chat, ChatSection, ConversationInfo, height));
            background-color: #fff;
            position: relative;

            .open-button {
                z-index: 1;
                position: absolute;
                top: 15px;
                left: -35px;
            }

            &__data {
                height: 90%;
                overflow: auto;

                &__filters {
                    margin-top: 10px;
                    text-align: center;
                }
            }

            &__actions {
                height: 10%;
                padding-inline: 10px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                ;
            }
        }
    }
}

.AgentChat {
    .LinesSection {
        width: 5%;

        @media screen and (max-width:980px) {
            width: 7%;
        }
    }

    .ConversationsSection {
        width: 25%;
        grid-template-rows: 80px 1fr;
        display: grid;
    }

    .ChatSection {
        width: 70%;


        &__body {
            &__attachment-file {
                &-fixed {
                    width: calc(100% - 5% - 25%);
                }
            }
        }
    }
}

.SupervisorChat {
    overflow: hidden;

    .LinesSection {
        width: 5%;
    }

    .AgentsSection {
        width: 20%;
    }

    .ConversationsSection {
        width: 25%;
    }

    .ConversationsUnassignedSection {
        width: 25%;
    }

    .ChatSection {
        width: 50%;
        z-index: 3;

        &__body {
            overflow: hidden;
            height: calc(100vh - map-get($menubar, height) - map-get($Chat, ChatSection, ConversationInfo, height));
            // overflow: hidden;
            position: relative;

            &__attachment-file {
                &-fixed {
                    width: calc(100% - 5% - 20% - 25%);
                }
            }

            &--finished-conversation {
                // height: calc(100vh - map-get($menubar, height));
            }

            &.contact-info-open {
                overflow: initial;

                .ChatSection__contact-info {
                    translate: 0;
                }

                .open-button {
                    left: -23px;
                }
            }
        }

        &__contact-info {
            width: 100%;
            translate: 100%;
            transition: translate 150ms ease-in-out;
            position: absolute;

            // &.open {


            //     // .open-button {
            //     //     left: 22px;
            //     // }
            // }
        }
    }
}

/*---------------------------------------
    @Component LineIconItem
-----------------------------------------*/
.LineIconItem {
    &__body {
        position: relative;
        width: 50px;
        height: 50px;

        @media screen and (max-width:900px) {
            width: 25px;
            height: 25px;

        }

        &--name {
            background-color: #e8ecf2;
        }

        .LineIconItemPhoto {
            &__body {
                overflow: hidden;
                background-color: white;
                border-radius: 20%;
                box-shadow: 0 4px 4px rgb(232 236 242 / 21%), 0 4px 4px #d3d6da;

                &__photo {

                    display: flex;
                    align-items: center;
                    text-align: center;

                    img {
                        object-fit: contain;
                        margin: auto;
                    }
                }
            }
        }

        .LineIconItemName {
            height: 100%;

            &__body {
                height: 100%;

                &__name {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    font-size: .6em;
                    text-align: center;
                    font-weight: 700;
                }
            }
        }

        &__isAlert {
            position: absolute;
            top: -5px;
            right: -5px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: red;
            color: white;
            text-align: center;
            font-weight: 700;
            content: '!';
        }
    }
}

/*---------------------------------------
    @Component AgentsHeader
-----------------------------------------*/
.AgentsHeader {
    &__body {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        padding-right: 0.5em;

        &__info {
            margin-left: auto;
            margin-top: auto;
            margin-right: auto;
            margin-bottom: auto;
        }
    }
}

.AgentsHeaderInfoItem {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
}

/*---------------------------------------
    @Component AgentConversationItem
-----------------------------------------*/
.AgentConversationItem {



    &--lazy {
        cursor: wait !important;
    }

    &__body {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;

        .AgentConversationItemInfo {
            margin-top: auto;
            margin-bottom: auto;
            padding-left: 10px;
            min-width: 0;
            overflow: hidden;

            &__body {

                &__name {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &__area {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .AgentConversationItemDetail {
            margin-left: auto;
            margin-top: auto;
            margin-bottom: auto;
        }
    }
}

/*---------------------------------------
    @Component AgentConversationItemIcon
-----------------------------------------*/
.AgentConversationItemIcon {
    &--lazy {
        cursor: wait;
        border-radius: 50%;
        @extend .animated-background;

        .AgentConversationItemIcon__body {
            background-color: initial;
        }
    }

    &__body {
        border-radius: 50%;
        background-color: #53BDEB;
        width: 50px;
        height: 50px;

        &__icon {
            position: relative;
            text-align: center;
            vertical-align: middle;
            line-height: 50px;
            font-weight: 700;
            color: white;

            .status {
                border: 2px solid white;
                border-radius: 50%;
                position: absolute;
                right: -2px;
                bottom: -2px;

                &.ONLINE {
                    color: #3a8939;
                }

                &.TO_GET_OUT {
                    color: orange;
                }

                &.AWAY {
                    color: gray;
                }

                &.DISCONNECTED {
                    color: gray;
                }
            }
        }
    }
}

/*---------------------------------------
    @Component AgentConversationItemInfo
-----------------------------------------*/
.AgentConversationItemInfo {
    font-size: .9em;

    &--lazy {
        @extend .animated-background;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 5px;
        height: 20px;
    }

    &__body {
        &__name {
            font-weight: 700;
        }
    }
}

/*---------------------------------------
    @Component AgentConversationItemDetail
-----------------------------------------*/
.AgentConversationItemDetail {
    font-size: .9em;

    &--lazy {
        @extend .animated-background;
        width: 25% !important;
        height: 20px;
        margin-top: auto;
        margin-right: auto;
        margin-bottom: auto;
        margin-left: auto;
    }

    &__body {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        &__timeout {
            width: 20px;
            text-align: center;
            border-radius: 50%;
            background-color: red;
            color: white;
            margin: 5px 10px 0 5px;
            font-weight: 700;
        }
    }
}

/*---------------------------------------
    @Component ContactConversationItem
-----------------------------------------*/
.ContactConversationItem {

    &--lazy {
        cursor: wait !important;
    }

    &__body {
        display: flex;
        flex-direction: row;
        padding: 0.75em;

        .ContactConversationItemInfo {
            min-width: 0;

            // padding-top: 10px;
            margin-left: 10px;

            &__body {
                display: flex;
                height: 100%;
                flex-direction: column;
                justify-content: center;
                overflow: hidden;
            }
        }

        .ContactConversationItemDetail {
            margin-left: auto;
        }
    }
}

/*---------------------------------------
    @Component AvatarContact
-----------------------------------------*/
.AvatarContact {
    --color-avatar-background: #9AC987;

    &--lazy {
        cursor: wait;
        border-radius: 50%;
        @extend .animated-background;

        .AvatarContact__body {
            background-color: initial;
        }
    }

    &.bg-color {
        &-1 {
            --color-avatar-background: #4D8834;
        }

        &-2 {
            --color-avatar-background: #66988B;
        }

        &-3 {
            --color-avatar-background: #93B7AE;
        }

        &-4 {
            --color-avatar-background: #9AC987;
        }

        &-5 {
            --color-avatar-background: #C48966;
        }

        &-6 {
            --color-avatar-background: #DB93A0;
        }

        &-7 {
            --color-avatar-background: #EABA9D;
        }

        &-8 {
            --color-avatar-background: #D9B020;
        }
    }

    &__body {
        border-radius: 50%;
        background-color: var(--color-avatar-background);
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;

        &__icon {
            text-align: center;
            vertical-align: middle;
            font-weight: 700;

            &--light {
                color: white;
            }

            &--dark {
                color: black;
            }
        }
    }
}

/*---------------------------------------
    @Component ContactConversationItemInfo
-----------------------------------------*/
.ContactConversationItemInfo {
    &--lazy {
        @extend .animated-background;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 5px;
        height: 20px;
    }
}

/*---------------------------------------
    @Component ContactConversationItemDetail
-----------------------------------------*/
.ContactConversationItemDetail {
    &--lazy {
        @extend .animated-background;
        width: 25% !important;
        height: 20px;
        margin-top: auto;
        margin-right: auto;
        margin-bottom: auto;
        margin-left: auto;
    }

    &__body {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-end;
        gap: 0.25em;


        &__dateLastMessage {
            width: 100%;
            font-size: .8125em;
            text-align: right;
        }

        &__notifications {
            display: flex;

            >*:not(:last-child) {
                margin-right: 3px;
            }
        }

        &__timeElapsedLastMessage {
            font-size: .75em;
            padding-inline: 0.5em;
            text-align: center;
            border-radius: 15px;
            background-color: red;
            color: white;
            // margin: 5px 10px 0 5px;
        }

        &__newMessages {
            font-size: .75em;
            min-width: 16px;
            text-align: center;
            border-radius: 1.1em;
            padding-inline: 0.4em;
            background-color: #65d072;
            color: white;
            // margin: 5px 10px 0 5px;
        }

        &__reassigned {
            width: 20px;
            text-align: center;
            border-radius: 50%;
            background-color: #f4ee56;
            color: black;
            // margin: 5px 10px 0 5px;
        }


        &__sendingMessage {
            width: 20px;
            text-align: center;
            border-radius: 50%;
            color: #8696a0;
            // margin: 5px 10px 0 5px;
        }

        &__timeout {
            width: 20px;
            text-align: center;
            border-radius: 50%;
            background-color: #d4d4d4;
            color: black;
            // margin: 5px 10px 0 5px;
            font-weight: 700;
        }

        &__lastMessageIsViewed {
            width: 20px;
            text-align: center;
            border-radius: 50%;
            color: #73c9ef;
            // margin: 5px 10px 0 5px;
        }

        &__lastMessageIsReceived {
            width: 20px;
            text-align: center;
            border-radius: 50%;
            color: #95a3ac;
            // margin: 5px 10px 0 5px;
            font-weight: 700;
        }
    }
}

/*---------------------------------------
    @Component AgentsHeaderFiltersSection
-----------------------------------------*/
.AgentsHeaderFiltersSection {
    text-align: center;
    margin-left: auto;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: auto;
    padding-left: 10px;

    &__body {
        display: flex;

        &__filter {

            .p-button.p-button.p-component.p-button-rounded {
                margin-left: 10px !important;
                height: 2.5em;
                width: 2.5em;
                padding: 0 !important;
            }
        }
    }
}

/*---------------------------------------
    @Component ConversationsHeaderFiltersSection
-----------------------------------------*/
.ConversationsHeaderFiltersSection {
    text-align: center;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;

    &__body {
        display: flex;

        &__filter {
            .p-button.p-button.p-component.p-button-rounded {
                margin-left: 10px !important;
                padding: 0 !important;
                height: 2.5em;
                width: 2.5em;
            }

        }
    }
}

/*---------------------------------------
    @Component ChatActionEmojisItem
-----------------------------------------*/
.ChatActionEmojisItem {
    &__body {
        width: 30px;
        font-size: 25px;
        cursor: pointer;
    }
}

/*---------------------------------------
    @Component ChatActionAttachmentFileItem
-----------------------------------------*/
.ChatActionAttachmentFileItem {
    &__body {
        width: 30px;
        font-size: 25px;
        cursor: pointer;
    }
}

/*---------------------------------------
    @Component ChatActionQuickReplyItem
-----------------------------------------*/
.ChatActionQuickReplyItem {
    &__body {
        width: 30px;
        font-size: 25px;
        cursor: pointer;
    }
}

/*---------------------------------------
    @Component ChatActionInputItem
-----------------------------------------*/
.ChatActionInputItem {
    min-height: 40px;

    &__body {
        &__input {
            .p-field {
                margin: 0 !important;
                padding: .5em 0 0 0 !important;
            }
        }
    }
}

/*---------------------------------------
    @Component ChatActionButtonSendMessageItem
-----------------------------------------*/
.ChatActionButtonSendMessageItem {
    &__body {
        width: 30px;
        font-size: 25px;
        cursor: pointer;
    }
}

/*---------------------------------------
    @Component:ConversationsHeader:Filter FilterStatus
-----------------------------------------*/
.FilterStatus {
    width: auto !important;

    .p-slidemenu-wrapper {
        width: 17.1rem;
        height: 15rem !important;

        .p-slidemenu-rootlist {
            width: fit-content !important;

            .fa-square-check {
                color: #194893 !important;
            }
        }
    }

}

/*---------------------------------------
    @Component:ConversationsHeader:Filter FilterOrder
-----------------------------------------*/
.FilterOrder {
    width: auto !important;

    .p-slidemenu-wrapper {
        width: 19rem;
        height: 6rem !important;

        .p-slidemenu-rootlist {
            width: fit-content !important;

            .pi-circle-on {
                color: #194893 !important;
            }
        }
    }
}

/*---------------------------------------
    @Component:MenuBar Default
-----------------------------------------*/
.p-menubar-root-list {
    .p-submenu-list {
        z-index: 2;
    }
}

/*---------------------------------------
    @Component:Chat status messages
-----------------------------------------*/
.chat-status-message {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    h2 {
        text-align: center;
    }
}